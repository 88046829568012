import { Notifications } from "@/utils";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { ParcelProps } from "./types";
import "./index.css";
import { queryClient } from "@/lib";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const App = (props: ParcelProps) => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
        <Routes {...props} />
        <Notifications />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
