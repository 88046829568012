import { useRef } from "react";
import { ScrollArea } from "@/components/scroll-area";
import { ExtractReceiptType } from "@/types";
import {
  descriptionStyles,
  formatBankSlip,
  formatCurrency,
  formatDocument,
  formatExternalId,
  Hooks,
  TransactionStatus,
  usePrint,
  useSendPDFtoMobile,
} from "@/utils";
import { formatDate } from "@/utils/date";
import {
  getTransactionStatusColor,
  getTransactionStatusTitle,
} from "@/utils/get-transaction-status";
import { Button } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { VerticalLogo } from "../vertical-logo";

export const PaymentReceiptV2 = (props: ExtractReceiptType<"PAYMENT_SLIP">) => {
  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const {
    description,
    type,
    destination,
    transactionDate,
    amount,
    origin,
    status,
  } = props;

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = usePrint({
    content: () => componentRef.current,
  });

  const { isAndroid, isIos } = Hooks.useMobileDetect();

  const isMobileDevice = isAndroid() || isIos();

  const { generateAndSendPDF } = useSendPDFtoMobile();

  const generatePDF = async () => {
    generateAndSendPDF(componentRef.current);
  };

  const isCashOut = type === "CASH_OUT";

  const pendingStatus: Array<TransactionStatus> = [
    "REVERSAL_PENDING",
    "PROCESSING",
    "PENDING",
  ];

  const isPending = pendingStatus.includes(
    status.toLocaleUpperCase() as TransactionStatus,
  );

  const {
    name: beneficiaryName,
    document: beneficiaryDocument,
    paymentSlip: beneficiaryData,
  } = destination;

  const { name: payerName, document: payerDocument } = origin;

  return (
    <div className="flex h-[100svh] flex-col overflow-hidden pt-6" id="receipt">
      <h4 className="font-inter -mt-2 ml-4 pb-4 text-2xl font-bold text-neutral-darkest">
        Comprovante
      </h4>
      <div
        className="mb-6 flex w-full flex-col items-center overflow-hidden"
        ref={componentRef}
      >
        <ScrollArea className="flex h-full w-full flex-col px-6" id="infos">
          <VerticalLogo />
          <div className="flex w-full flex-col items-center overflow-hidden">
            <h5 className="font-base text-sm/md font-bold text-neutral-darkest">
              Pagamento de boleto
            </h5>
            <h5
              className={twMerge(
                "mb-6 font-base text-sm/md font-bold",
                getTransactionStatusColor(props),
              )}
            >
              {getTransactionStatusTitle(props)}
            </h5>
            <div className="flex h-full w-full flex-col overflow-hidden">
              {description && (
                <>
                  <hr className="h-[1px] w-full bg-neutral-light" />
                  <dl className="my-4 h-full w-full">
                    <dt className={definitionTerm()}>Mensagem</dt>
                    <dd
                      className={twMerge(definitionDetail(), "mt-1 text-start")}
                    >
                      {description}
                    </dd>
                  </dl>
                </>
              )}
              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                {isCashOut && (
                  <div className="col-span-2">
                    <dt className={definitionTerm()}>Código de barras</dt>
                    <dd
                      className={twMerge(
                        definitionDetail(),
                        "break-all text-start",
                      )}
                    >
                      {formatBankSlip(beneficiaryData.barcodeNumber)}
                    </dd>
                  </div>
                )}
                <dt className={definitionTerm()}>Beneficiário</dt>
                <dd className={twMerge(definitionDetail())}>
                  {beneficiaryName}
                </dd>
                <dt className={definitionTerm()}>CPF/CNPJ</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatDocument(beneficiaryDocument)}
                </dd>
                {isCashOut && (
                  <>
                    <dt className={definitionTerm()}>Data de vencimento</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {formatDate(beneficiaryData.dueDate)}
                    </dd>
                    <dt className={definitionTerm()}>Data de pagamento</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {formatDate(transactionDate)}
                    </dd>
                    <dt className={definitionTerm()}>Valor</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {formatCurrency(amount)}
                    </dd>
                    <dt className={definitionTerm()}>Multa</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {formatCurrency(beneficiaryData.fine)}
                    </dd>
                    <dt className={definitionTerm()}>Juros</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {formatCurrency(beneficiaryData.interest)}
                    </dd>
                    <dt className={definitionTerm()}>Desconto</dt>
                    <dd className={twMerge(definitionDetail())}>
                      {formatCurrency(beneficiaryData.discount)}
                    </dd>
                  </>
                )}
              </dl>

              <hr className="h-[1px] w-full bg-neutral-light" />
              <dl className={definitionContainer()}>
                <dt className={definitionTerm()}>Pago por</dt>
                <dd className={twMerge(definitionDetail())}>{payerName}</dd>
                <dt className={definitionTerm()}>CPF/CNPJ</dt>
                <dd className={twMerge(definitionDetail())}>
                  {formatDocument(payerDocument)}
                </dd>
              </dl>

              <hr className="h-[1px] w-full bg-neutral-light" />
              <div className="col-span-2">
                <dl className="mt-4 w-full">
                  <dt className={definitionTerm()}>Id da Operação</dt>
                  <dd
                    className={twMerge(
                      definitionDetail(),
                      "break-all text-start",
                    )}
                  >
                    {formatExternalId(props)}
                  </dd>
                </dl>
              </div>
              {isPending && (
                <>
                  <hr className="my-4 h-[1px] w-full bg-neutral-light" />
                  <div className="col-span-2 w-full font-base text-x2s/5">
                    <span className="text-status-warning-dark">
                      Importante:
                    </span>
                    <span className="text-neutral-dark">
                      {" "}
                      Seu pagamento já foi enviado. O comprovante ainda está
                      sendo processado e em breve estará disponível.
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </ScrollArea>
      </div>
      {isMobileDevice ? (
        <Button.Root className="m-6 mt-auto" onClick={generatePDF}>
          <Button.Slot name="ComputersShare" />
          Compartilhar comprovante
        </Button.Root>
      ) : (
        <Button.Root className="m-6 mt-auto" onClick={handlePrint}>
          <Button.Slot name="GeralPrint" />
          Imprimir comprovante
        </Button.Root>
      )}
    </div>
  );
};
