import { parseISO } from "date-fns";

export const isValidDate = (date: Date) => {
  return date instanceof Date && Number.isFinite(date.getTime());
};

export const formatDate = (value: string) => {
  const date = parseISO(value);

  if (!isValidDate(date)) return "";

  return date.toLocaleDateString("pt-BR");
};
