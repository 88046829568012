import { BaseReceipt, ReceiptDetailV2 } from "@/types";
import { getTransactionStatus } from "@/utils";

export const getTransactionStatusTitle = (receipt: ReceiptDetailV2) => {
  return getTransactionStatus({
    category: receipt.transactionType,
    status: receipt.status,
    type: receipt.type,
  });
};

export const getTransactionStatusColor = (receipt: ReceiptDetailV2) => {
  const isCashin = receipt.type === "CASH_IN";

  if (isCashin) return "text-status-success-dark";

  const pendingStatus: Array<BaseReceipt["status"]> = [
    "REVERSAL_PENDING",
    "PROCESSING",
    "PENDING",
  ];

  if (
    pendingStatus.includes(receipt.status) &&
    receipt.transactionType === "PAYMENT_SLIP"
  )
    return "text-status-warning-dark";

  const errorStatus: Array<BaseReceipt["status"]> = [
    "SCHEDULE_CANCELED",
    "ERROR",
    "FAILED",
    "REJECTED",
    "CANCELLED",
    "DENIED",
    "EXPIRED",
  ];

  if (errorStatus.includes(receipt.status)) return "text-status-error-dark";

  return "text-primary-main";
};
