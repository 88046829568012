import { useCheckAppVersion } from "@/api";
import { ParcelProps } from "@/types";
import { useNewStatementPermission } from "@/utils";
import { ReceiptV1 } from "./receipt-v1";
import { SimpleReceipt } from "./simple-receipt";

export const ReceiptRoot = (props: ParcelProps) => {
  useCheckAppVersion();

  const useNewStatement = useNewStatementPermission();

  return useNewStatement ? (
    <SimpleReceipt {...props} />
  ) : (
    <ReceiptV1 {...props} />
  );
};
