import {
  API,
  appVersionQueryFactory,
  formatCurrency,
  getReceipt,
  getReceiptRoutesData,
  getTransactionStatus,
  getVertical,
  Hooks,
  JSpdf,
  Notifications,
  queries,
  RECEIPT_CDN,
  setHeader,
  useAccountStore,
  useNewStatementPermission,
  useSendNativeEvent,
  useSendPDFtoMobile,
  Vertical,
  type TransactionCategory,
  type TransactionErrorStatus,
  type TransactionFee,
  type TransactionStatus,
  type TransactionType,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";

const routes = getReceiptRoutesData();

export {
  getReceipt,
  API,
  appVersionQueryFactory,
  formatCurrency,
  getVertical,
  Hooks,
  getTransactionStatus,
  type TransactionCategory,
  type TransactionErrorStatus,
  type TransactionFee,
  type TransactionStatus,
  type TransactionType,
  JSpdf,
  Notifications,
  queries,
  RECEIPT_CDN,
  routes,
  setHeader,
  useAccountStore,
  useSendNativeEvent,
  useSendPDFtoMobile,
  Vertical,
  useNewStatementPermission,
};
