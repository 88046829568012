import { useGetReceiptV2 } from "@/api";
import { NotFound } from "@/components/not-found";
import { P2PReceiptV2 } from "@/components/p2p-receipt";
import { PaymentReceiptV2 } from "@/components/payment-receipt";
import { PixReceiptV2 } from "@/components/pix-receipt";
import { TedReceiptV2 } from "@/components/ted-receipt";
import {
  ParcelProps,
  ReceiptOrigin,
  ReceiptType,
  TransactionMovement,
} from "@/types";
import { useParams } from "react-router-dom";

export const ReceiptV2 = (props: ParcelProps) => {
  const params = useParams<{
    receiptId: string;
    receiptType: ReceiptType;
    receiptOrigin: ReceiptOrigin;
    movement: TransactionMovement;
  }>();

  const receiptId = props.receiptId || params?.receiptId;

  const receiptOrigin = props.receiptOrigin || params.receiptOrigin;

  const { data } = useGetReceiptV2({ receiptId, receiptOrigin });

  const getReceipt = () => {
    switch (data.transactionType) {
      case "PIX":
        return <PixReceiptV2 {...data} />;

      case "TED":
        return <TedReceiptV2 {...data} />;

      case "P2P":
        return <P2PReceiptV2 {...data} />;

      case "PAYMENT_SLIP":
        return <PaymentReceiptV2 {...data} />;

      default:
        return <NotFound />;
    }
  };

  return getReceipt();
};
