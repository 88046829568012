import { useCallback, useState } from "react";
import { ErrorFeedback } from "@/components/error-feedback";
import {
  ParcelProps,
  ReceiptOrigin,
  ReceiptType,
  TransactionMovement,
} from "@/types";
import { descriptionStyles, formatCurrency, getReceipt } from "@/utils";
import { Button } from "@hyperlocal/vital2";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { VerticalLogo } from "../vertical-logo";
import { ReceiptV2 } from "./receipt-v2";

type ReceiptComponent = "simple-receipt" | "advanced-receipt";

type SimpleReceiptComponentProps = {
  handleReceipt: (state: ReceiptComponent) => void;
};

const SimpleReceiptComponent = ({
  handleReceipt,
}: SimpleReceiptComponentProps) => {
  const { definitionTerm, definitionDetail, definitionContainer } =
    descriptionStyles();

  const receipt = getReceipt();

  const getTitle = () => {
    const titleMap: Record<(typeof receipt)["type"], string> = {
      p2p: "Transferência enviada",
      ted: "Transferência enviada",
      pix: "Pix enviado",
      payment_slip: "Pagamento em andamento",
    };

    return titleMap[receipt.type] || titleMap.p2p;
  };

  const handleAdvancedReceipt = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleReceipt("advanced-receipt"));
      }, 1000); // A pedido do back
    });
  };

  return (
    <div className="flex h-[100svh] flex-col overflow-hidden p-6" id="receipt">
      <div className="mb-6 flex h-full w-full flex-col items-center overflow-hidden">
        <VerticalLogo />
        <div className="flex h-full w-full flex-col items-center overflow-hidden">
          <h5 className="mb-6 font-base text-sm/md font-bold text-primary-main">
            {getTitle()}
          </h5>
          <div className="flex h-full w-full flex-col overflow-hidden">
            <dl className={definitionContainer()}>
              <dt className={definitionTerm()}>Nome</dt>
              <dd className={twMerge(definitionDetail())}>
                {receipt?.beneficiaryName}
              </dd>
              <dt className={definitionTerm()}>Valor</dt>
              <dd className={twMerge(definitionDetail())}>
                {formatCurrency(receipt?.value)}
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <Button.Root onClick={handleAdvancedReceipt}>Ver comprovante</Button.Root>
    </div>
  );
};

export const SimpleReceipt = (props: ParcelProps) => {
  const params = useParams<{
    receiptId: string;
    receiptType: ReceiptType;
    receiptOrigin: ReceiptOrigin;
    movement: TransactionMovement;
  }>();

  const receiptOrigin = props.receiptOrigin || params.receiptOrigin;

  const firstReceipt: ReceiptComponent =
    receiptOrigin === "statements" ? "advanced-receipt" : "simple-receipt";

  const [componentToRender, setComponentToRender] =
    useState<ReceiptComponent>(firstReceipt);

  const handleReceipt = useCallback(
    (state: ReceiptComponent) => setComponentToRender(state),
    [],
  );

  const componentMap: Record<ReceiptComponent, JSX.Element> = {
    "simple-receipt": <SimpleReceiptComponent handleReceipt={handleReceipt} />,
    "advanced-receipt": <ReceiptV2 {...props} />,
  };

  return <ErrorFeedback>{componentMap[componentToRender]}</ErrorFeedback>;
};
