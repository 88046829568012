import { tv } from "tailwind-variants";

export const descriptionStyles = tv({
  slots: {
    definitionTerm:
      "font-base font-medium text-neutral-darkest text-xs/default w-fit",
    definitionDetail: "font-base text-neutral-dark text-xs/md text-end",
    definitionContainer:
      "grid grid-cols-auto-fr my-4 gap-4 w-full items-center",
  },
});
